import * as React from 'react'
import Layout from '../templates/layout'
import Hero from '../components/Hero'

import '../scss/possible-side-effects.scss'

import DownloadAction from '../components/DownloadAction'
import PageSuggestionBanner from '../components/PageSuggestionBanner'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import { ReactElement } from 'react'
import { getImage, StaticImage, withArtDirection } from 'gatsby-plugin-image'
import ExternalLink from '../components/ExternalLink'

type ListItemProps = {
	Img: ReactElement<typeof StaticImage>
	title: string
	text: string
}

const ListItem = ({ Img, title, text }: ListItemProps) => {
	return (
		<div className='grid grid-cols-10 mt-5'>
			<div className='col-span-1 flex justify-center items-start mr-2'>{Img}</div>
			<div className='col-span-9 md:pl-0 pl-4'>
				<div className='font-bold'>{title}</div>
				<div>{text}</div>
			</div>
		</div>
	)
}

const divideItemsData = [
	{
		title: 'Limit sun exposure',
		text:
			'Sunburn can make rash symptoms worse. Avoid too much time in the sun, use sunscreen, and protect your skin with a hat and long sleeves.',
	},
	{
		title: 'Keep skin soft',
		text:
			'Use an alcohol- and perfume-free skin cream often. Avoid hot showers and products that dry your skin.',
	},
	{
		title: 'Talk to your healthcare team',
		text:
			'Speak up if rash makes it hard for you to carry out normal daily activities.',
	},
]

type Props = {
	data: any
	location: Location
}

const PossibleSideEffects = ({ data, location }: Props) => {
	return (
		<Layout>
			<SEO
				SEOData={{
					title: 'Possible Side Effects | EXKIVITY® (mobocertinib)',
					description:
						'Know the possible side effects of EXKIVITY® (mobocertinib) in treating EGFR Exon20 insertion-positive non-small cell lung cancer (NSCLC) after platinum-based chemotherapy. Please see Patient Information, Safety, and Boxed Warning.',
					path: location.pathname,
				}}
			/>
			<Hero bgImage={data.heroImage} imageAlt=''>
				<h1 className='text-white'>
					Understanding
					<br />
					Side Effects
				</h1>
			</Hero>
			<div className='grid grid-cols-12' id='possible-side-effects-container'>
				<div className='col-start-2 col-span-10 mt-10'>
					<h2 className='text-pompadourViolet font-bold text-2xl mb-2'>
						Here’s what you might experience
					</h2>
					<div>
						EXKIVITY may cause side effects. Not every person will experience the same
						side effects, and for some people, some side effects may be more severe
						than others.{' '}
						<span>You and your healthcare team should monitor for side effects</span>{' '}
						and work together to manage them early so you can{' '}
						<span>get the most out of treatment</span>.
					</div>
				</div>
				<div className='col-start-2 col-span-10 mt-6'>
					<div className='text-mediumVioletRed font-bold'>
						Possible serious side effects you should be aware of:
					</div>
					<ListItem
						Img={
							<StaticImage
								src='../images/possible-side-effects/lightining.png'
								alt=''
							/>
						}
						title='Changes in the electrical activity of your heart'
						text='In some people, EXKIVITY can affect the electrical activity of the heart, causing irregular heartbeats. This can be life-threatening and can lead to death. Your healthcare provider will check the electrical activity of your heart with a test called an electrocardiogram (ECG) and do blood tests to check your electrolytes before you start EXKIVITY and during treatment. Tell your healthcare provider right away if you feel dizzy, lightheaded, faint or have an irregular heartbeat.'
					/>
					<ListItem
						Img={
							<StaticImage
								src='../images/possible-side-effects/lung-problems.png'
								alt=''
							/>
						}
						title='Lung problems'
						text='EXKIVITY may cause severe lung problems that may lead to death. It may be hard to tell lung problems from your lung cancer symptoms, so tell your healthcare team right away if you develop any new or worsening symptoms, including trouble breathing or shortness of breath, cough, chest pain, or fever.'
					/>
					<ListItem
						Img={
							<StaticImage src='../images/possible-side-effects/heart.png' alt='' />
						}
						title='Heart problems, including heart failure'
						text='EXKIVITY may cause heart problems, including heart failure. Your healthcare provider should check your heart function before you start EXKIVITY and during treatment. Tell your healthcare provider right away if you have any signs or symptoms of a heart problem, including feeling like your heart is pounding or racing, shortness of breath, chest pain, swelling of your ankles and feet, or feeling faint.'
					/>
					<ListItem
						Img={
							<StaticImage src='../images/possible-side-effects/diarrhea.png' alt='' />
						}
						title='Diarrhea'
						text='Diarrhea is common with EXKIVITY and may sometimes be severe. Diarrhea can cause you to lose too much body fluid (dehydration) and kidney problems. Tell your healthcare provider right away if you have any loose stools or have stools more often than is normal for you.'
					/>
					<div className='grid grid-cols-10 mt-20'>
						<div className='col-span-1 flex justify-center items-start'>
							<StaticImage
								src='../images/possible-side-effects/checklist.png'
								alt=''
							/>
						</div>
						<div className='col-span-9 md:pl-0 pl-4 md:pb-0 pb-3'>
							<div className='flex flex-col justify-between'>
								<div className='text-pompadourViolet text-2xl font-bold'>
									How to manage diarrhea if it occurs
								</div>
								<div className='mt-8'>
									<div className='text-mediumVioletRed font-bold'>ANTICIPATE</div>
									<div className='md:flex md:pt-3 pt-1'>
										<div className='list pr-3'>
											<ul className='list-disc marker:text-mediumVioletRed ml-4'>
												<li className='mb-2'>
													Diarrhea should be treated <strong>right away</strong>
												</li>
												<li className='mb-2'>
													It typically <strong>occurs within 5 days</strong> but can occur as
													soon as 24 hours after starting EXKIVITY. It can continue to occur
													at any point during therapy
												</li>
												<li className='mb-2'>It is most often mild to moderate</li>
											</ul>
										</div>
										<div className='list'>
											<ul className='list-disc marker:text-mediumVioletRed ml-4'>
												<li className='mb-2'>
													It is recommended that you have an{' '}
													<strong>
														antidiarrheal medicine (such as loperamide) at home
													</strong>{' '}
													so you can treat diarrhea promptly when it happens
												</li>
												<li className='mb-2'>
													<strong>Talk to your healthcare team</strong> before taking any
													medication
												</li>
											</ul>
										</div>
									</div>
									<div className='bg-weakYellow px-7 py-4 my-6 text-center'>
										In general, the sooner your diarrhea is addressed, the more likely it
										will be controlled and tolerable
									</div>
									<div className='text-mediumVioletRed font-bold'>ADDRESS</div>
									<div className='md:flex pt-4 pb-6'>
										<div className='list pr-3'>
											<ul className='list-disc marker:text-mediumVioletRed ml-4'>
												<li className='mb-2'>
													Your healthcare team is likely to instruct you to take an
													antidiarrheal medicine at first sign of loose or poorly formed
													stool
												</li>
												<li className='mb-2'>
													<strong>Tell your healthcare team immediately</strong> if you have
													any loose stools or if your bowel movements become more frequent
													than normal
												</li>
											</ul>
										</div>
										<div className='list'>
											<ul className='list-disc marker:text-mediumVioletRed ml-4'>
												<li className='mb-2'>
													<strong>Stay hydrated:</strong> drink enough liquids (about 8 to 10
													cups) every day to replace lost fluids and electrolytes
												</li>
												<li className='mb-2'>
													<strong>Eat simply:</strong> eat smaller, more frequent meals of
													plain foods such as bananas, rice, applesauce, and toast. Avoid
													fatty and high-fiber foods
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='grid grid-cols-12 bg-lightGrey'>
				<div className='col-start-2 col-span-10 z-0 py-20'>
					<div className='grid grid-cols-10 mb-20'>
						<div className='md:col-span-1 col-span-2 md:pr-0 pr-3'>
							<StaticImage
								src='../images/possible-side-effects/skin-reactions.png'
								alt=''
							/>
						</div>
						<div className='md:col-span-9 col-span-8'>
							<div className='text-pompadourViolet md:text-2xl text-xl font-bold'>
								How to manage skin reactions if they occur
							</div>
							<div className='my-2'>
								Some people taking EXKIVITY experienced skin reactions, but they were
								mostly mild or moderate. They included rash, dry skin, and nail
								inflammation. Common symptoms of rash were itchy, dry, or swollen red
								skin, and red, inflamed, scaly skin.
							</div>
							<div>
								If you experience any of these skin reactions, be sure to talk to your
								healthcare team right away. Your team may refer you to a dermatologist.
								If your rash is severe, they may reduce, withhold, or permanently
								discontinue your dose of EXKIVITY.
							</div>
						</div>
					</div>
					<div
						className={
							'md:flex md:divide-x-2 md:divide-y-0 divide-y-2 divide-white border-t-2 border-b-2 border-white'
						}
					>
						{divideItemsData.map((item, index) => (
							<div
								className={'px-5 py-7 w-full lg:w-1/3'}
								key={`divide-item-${index}`}
							>
								<div className='text-mediumVioletRed font-bold'>{item.title}</div>
								<div>{item.text}</div>
							</div>
						))}
					</div>
					<div className='bg-weakYellow md:px-8 px-2 py-8 my-10 text-center'>
						<div className='text-mediumVioletRed md:text-2xl text-xl font-bold md:px-0 px-2'>
							The importance of speaking up
						</div>
						<div className='my-4'>
							Sometimes people worry about mentioning side effects to their doctors or
							nurses—they don’t want to bother them, or they’re scared of being taken
							off their medication.
						</div>
						<div>
							If you have any side effect that interferes with daily life or that does
							not go away,{' '}
							<span className='text-mediumVioletRed'>
								tell your healthcare team right away
							</span>
							. Addressing side effects as soon as possible may{' '}
							<span className='text-mediumVioletRed'>keep them</span> from getting
							worse.
						</div>
						<div className='text-mediumVioletRed md:text-2xl text-xl my-4 font-bold md:px-0 px-2'>
							Take notes on how you are feeling and share them with your healthcare
							team.
						</div>
					</div>
					<div className='px-6 mb-12'>
						You may report side effects to FDA at 1-800-FDA-1088 or{' '}
						<ExternalLink
							href='https://www.fda.gov/medwatch'
							className='text-mediumVioletRed underline'
						>
							www.fda.gov/medwatch
						</ExternalLink>
						. You may also report side effects directly to Takeda Oncology at
						1-844-817-6468 or{' '}
						<span className='text-mediumVioletRed underline'>
							<a href='mailto:globaloncologymedinfo@takeda.com'>
								GlobalOncologyMedinfo@Takeda.com
							</a>
						</span>
						.
					</div>
					<DownloadAction
						text='Download the Treatment Guide'
						images={{
							desktop:
								data.downloadTreatmentGuideDesktop.childImageSharp.gatsbyImageData,
							mobile:
								data.downloadTreatmentGuideMobile.childImageSharp.gatsbyImageData,
						}}
						fileURL='/brochures/Exkivity Patient Treatment Guide.pdf'
					/>
					<PageSuggestionBanner
						header='Where to turn for more information'
						text='Become an active participant in your treatment journey.'
						buttonText='Explore useful resources'
						to='/resources'
					/>
				</div>
			</div>
		</Layout>
	)
}
export default PossibleSideEffects

export const query = graphql`
	{
		heroImage: file(
			relativePath: { eq: "possible-side-effects/hero-possible-side-effects.png" }
		) {
			...heroImage
		}
		downloadTreatmentGuideMobile: file(
			relativePath: { eq: "resources-treatment-guide-thumb.png" }
		) {
			...downloadActionImage
		}
		downloadTreatmentGuideDesktop: file(
			relativePath: { eq: "treatment-guide-thumbnail.png" }
		) {
			...downloadActionImage
		}
	}
`
